import { toArray } from "@rollup-io/engineering";
import { AxiosResponse } from "axios";
import { destroy, flow, Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import {
  Analysis,
  AnalysisInput,
  AnalysisOutput,
  CreateAnalysisDto,
  CreateAnalysisInputDto,
  CreateAnalysisOutputDto,
  toAnalysisInputSnapshot,
  toAnalysisOutputSnapshot,
  toAnalysisSnapshot,
} from "@rollup-api/models/analysis";
import { ExecutionEnvironmentType } from "@rollup-api/models/execution-environments";
import { AnalysisInputStore, IAnalysisInput, IAnalysisInputMobxType } from "@store/Analysis/AnalysisInputStore";
import { AnalysisOutputStore, IAnalysisOutput, IAnalysisOutputMobxType } from "@store/Analysis/AnalysisOutputStore";
import { AnalysisStore, AnalysisType, IAnalysis, IAnalysisMobxType } from "@store/Analysis/AnalysisStore";
import { templateCode } from "@store/Analysis/templateCodeBlocks";
import appStore from "@store/AppStore";
import { getDefaultVariableName } from "@utilities/ExecutionEnvironments";
import { findNextAvailableName } from "@utilities/TextUtil";

import { rollupClient } from "../../core/api";

export const AnalysisModuleStore = types
  .model("AnalysisModuleStore", {
    analysisMap: types.map<IAnalysisMobxType>(AnalysisStore),
    analysisInputMap: types.map<IAnalysisInputMobxType>(AnalysisInputStore),
    analysisOutputMap: types.map<IAnalysisOutputMobxType>(AnalysisOutputStore),
  })
  .views(self => ({
    get analyses(): IAnalysis[] {
      return toArray<IAnalysis>(self.analysisMap);
    },
    get analysisInputs(): IAnalysisInput[] {
      return toArray<IAnalysisInput>(self.analysisInputMap);
    },
    get analysisOutputs(): IAnalysisOutput[] {
      return toArray<IAnalysisOutput>(self.analysisOutputMap);
    },
    // Needed for rollup-engineering compatibility
    get codeBlockMap() {
      return self.analysisMap;
    },
  }))
  .actions(self => ({
    deleteAnalysis(id: string, notify = true) {
      const block = self.analysisMap.get(id);

      if (notify) {
        rollupClient.analysisModule.analyses.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting analysis", err);
        });
      }

      if (appStore.env.activeAnalysisId === id) {
        appStore.env.clearActiveAnalysis();
      }
      destroy(block);
      return true;
    },
    deleteAnalysisInput(id: string, notify = true) {
      const input = self.analysisInputMap.get(id);
      if (!input) {
        return false;
      }

      if (notify) {
        rollupClient.analysisModule.analysisInputs.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting code input", err);
        });
      }
      destroy(input);
      return true;
    },
    deleteAnalysisOutput(id: string, notify = true) {
      const output = self.analysisOutputMap.get(id);
      if (!output) {
        return false;
      }

      if (notify) {
        rollupClient.analysisModule.analysisOutputs.delete(id).catch((err: Error) => {
          showApiErrorToast("Error deleting code output", err);
        });
      }
      destroy(output);
      return true;
    },
  }))
  .actions(self => ({
    addExistingAnalysis(analysis: Analysis) {
      if (!analysis.id || self.analysisMap.has(analysis.id)) {
        return false;
      }
      self.analysisMap.put(toAnalysisSnapshot(analysis));
      return true;
    },
    createAnalysis: flow(function* createAnalysis(dto: CreateAnalysisDto): Generator<any, IAnalysis | undefined, any> {
      dto.type = dto.type ?? ExecutionEnvironmentType.Python;
      // Ensure the label is unique per workspace
      let labelPlaceholder = "New code block";
      if (dto.analysisType === AnalysisType.Spreadsheet) {
        labelPlaceholder = "New spreadsheet";
      }
      dto.label = findNextAvailableName(
        dto.label ?? labelPlaceholder,
        self.analyses.map(block => block.label)
      );
      try {
        const res: AxiosResponse<Analysis> = yield rollupClient.analysisModule.analyses.create(dto);
        if (res.status === 201) {
          const newBlock = toAnalysisSnapshot(res.data);
          return self.analysisMap.put(newBlock);
        } else {
          showApiErrorToast("Error creating analysis", new Error());
          return undefined;
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating analysis", err as Error);
        return undefined;
      }
    }),
    createAnalysisInput: flow(function* createAnalysisInput(analysis: IAnalysis, label?: string) {
      const dto: CreateAnalysisInputDto = {
        label: label ?? getDefaultVariableName(analysis.type, false),
        analysisId: analysis.id,
      };
      dto.label = findNextAvailableName(
        dto.label,
        analysis.inputs?.map(i => i.label)
      );

      try {
        const res = yield rollupClient.analysisModule.analysisInputs.create(dto);
        if (res.status === 201) {
          const input = toAnalysisInputSnapshot(res.data);
          self.analysisInputMap.put(input);
          analysis.inputs.push(input.id);
          return input;
        } else {
          showApiErrorToast("Error creating analysis input", new Error());
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating analysis input", err as Error);
      }
      return undefined;
    }),
    addExistingAnalysisInput(analysisInput: AnalysisInput) {
      if (!analysisInput?.analysisId) {
        return false;
      }

      const analysis = self.analysisMap.get(analysisInput.analysisId);
      if (!analysis) {
        return false;
      }

      const inputSnapshot = toAnalysisInputSnapshot(analysisInput);
      self.analysisInputMap.put(inputSnapshot);
      analysis.inputs.push(inputSnapshot.id);
      return true;
    },
    createAnalysisOutput: flow(function* createAnalysisOutput(analysis: IAnalysis, label?: string) {
      const dto: CreateAnalysisOutputDto = {
        label: label ?? getDefaultVariableName(analysis.type, true),
        analysisId: analysis.id,
      };
      dto.label = findNextAvailableName(
        dto.label,
        analysis.outputs?.map(i => i.label)
      );

      try {
        const res = yield rollupClient.analysisModule.analysisOutputs.create(dto);
        if (res.status === 201) {
          const output = toAnalysisOutputSnapshot(res.data);
          self.analysisOutputMap.put(output);
          analysis.outputs.push(output.id);
          return output;
        } else {
          showApiErrorToast("Error creating analysis output", new Error());
        }
      } catch (err) {
        console.warn(err);
        showApiErrorToast("Error creating analysis output", err as Error);
      }
      return undefined;
    }),
    addExistingAnalysisOutput(analysisOutput: AnalysisOutput) {
      if (!analysisOutput?.analysisId) {
        return false;
      }

      const analysis = self.analysisMap.get(analysisOutput.analysisId);
      if (!analysis) {
        return false;
      }

      const outputSnapshot = toAnalysisOutputSnapshot(analysisOutput);
      self.analysisOutputMap.put(outputSnapshot);
      analysis.outputs.push(outputSnapshot.id);
      return true;
    },
    // TODO: Reordering of blocks, inputs and outputs
  }))
  .actions(self => ({
    populateAnalysisTemplate: flow(function* populateAnalysisTemplate(analysis: IAnalysis) {
      // Templates can only be applied to empty code blocks
      if (!analysis?.id || analysis.analysisType !== AnalysisType.CodeBlock || analysis.code) {
        return false;
      }

      const inputNames = ["a", "b"];
      const outputNames = ["c"];

      // Create inputs and outputs if they don't already exist
      for (const outputName of outputNames) {
        if (!analysis.outputs.find(o => o.label === outputName)) {
          const output = yield self.createAnalysisOutput(analysis, outputName);
          if (!output) {
            return false;
          }
        }
      }

      for (const inputName of inputNames) {
        if (!analysis.inputs.find(i => i.label === inputName)) {
          const input = yield self.createAnalysisInput(analysis, inputName);
          if (!input) {
            return false;
          }
        }
      }

      // Set code based on language
      analysis.setCode(templateCode.get(analysis.type) ?? "", true);
      return true;
    }),
  }));

export interface IAnalysisModule extends Instance<typeof AnalysisModuleStore> {}

export interface IAnalysisModuleSnapshotIn extends SnapshotIn<typeof AnalysisModuleStore> {}

export interface IAnalysisModuleSnapshotOut extends SnapshotOut<typeof AnalysisModuleStore> {}
