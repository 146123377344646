import { RefObject, useMemo } from "react";
import { useWorkspace } from "@hooks/useWorkspace";
import {
  CellClassParams,
  ColDef,
  EditableCallbackParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { isAlive } from "mobx-state-tree";

import CheckboxCell from "@components/CatalogItems/Cells/CheckboxCell";
import { ICheckboxCellOwnProps } from "@components/CatalogItems/Cells/CheckboxCell/CheckboxCell";
import CheckboxHeaderCell from "@components/CatalogItems/Cells/CheckboxHeaderCell";
import { ICheckboxHeaderCellOwnProps } from "@components/CatalogItems/Cells/CheckboxHeaderCell/CheckboxHeaderCell";
import { statusCellValueFormatter } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import RequirementEditorCell from "@components/Requirements/RequirementsTable/Cells/RequirementEditorCell";
import RequirementsStatusCell, {
  IRequirementsStatusCellOwnProps,
} from "@components/Requirements/RequirementsTable/Cells/RequirementsStatusCell";
import { ERequirementsTableColumn } from "@components/Requirements/RequirementsTable/constants";
import { TextCellRenderer } from "@components/Table/CellRenderers/TextCellRenderer";
import { ITextCellRendererOwnProps } from "@components/Table/CellRenderers/TextCellRenderer/TextCellRenderer";
import { CommentCellRenderer } from "@components/Table/Components/CommentCellRenderer";
import {
  ICommentCellRendererParams,
  ICommentCellRendererValue,
} from "@components/Table/Components/CommentCellRenderer/CommentCellRenderer";
import { CommentHeader } from "@components/Table/HeaderComponents/CommentHeader";
import { RequirementBlockType, SuccessCriteriaType } from "@rollup-api/models";
import { IEntityData } from "@rollup-api/models/table-column/creteTableColumnDto";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { ITableColumn } from "@store/TableColumnStore";
import { EntityType } from "@store/types";
import { IWorkspace } from "@store/WorkspaceStore";
import { getPropertyInstanceById, removeTags } from "@utilities";
import { isDefined } from "@utilities/TypeGuards";

import { AddColumnHeaderRenderer } from "./Cells/AddColumnHeaderRenderer";
import { IAddColumnHeaderRendererOwnProps } from "./Cells/AddColumnHeaderRenderer/AddColumnHeaderRenderer";
import { IRequirementsActionsCellOwnProps, RequirementsActionsCell } from "./Cells/RequirementsActionsCell";
import { IRequirementsHeaderCellRendererOwnProps } from "./Cells/RequirementsHeaderCellRenderer/RequirementsHeaderCellRenderer";
import {
  descriptionCellRenderer,
  functionalTypeCellRenderer,
  levelCellRenderer,
  linkedBlockCellRenderer,
  linkedPropertyCellRenderer,
  methodCellRenderer,
  notesCellRenderer,
  rationaleCellRenderer,
  renderManualVerificationLabel,
  successCriteriaCellRenderer,
  titleCellRenderer,
  verificationCellRenderer,
  verificationStatusCellRenderer,
} from "./utils";

import styles from "./RequirementsTable.module.scss";
import checkboxHeaderCellStyles from "@components/CatalogItems/Cells/CheckboxHeaderCell/CheckboxHeaderCell.module.scss";

const getCheckboxColumnDef = (reqPage: IRequirementsPage): ColDef<IRequirementBlock> => {
  const selectedReqBlocksCount = appStore.ui.selectedRequirementBlockIds.length;
  return {
    colId: ERequirementsTableColumn.CHECKBOX,
    resizable: false,
    headerName: "",
    lockPosition: "left" as const,
    pinned: "left" as const,
    width: 25,
    cellRenderer: CheckboxCell,
    cellClass: styles.requirementsTableCheckboxCell,
    cellRendererParams: {
      onToggleSelection: reqBlock => appStore.ui.toggleSelectedRequirementBlockId(reqBlock.id),
    } satisfies ICheckboxCellOwnProps<IRequirementBlock>,
    valueGetter: (params: ValueGetterParams<IRequirementBlock>) => {
      const { data: requirementBlock } = params.node ?? {};
      if (requirementBlock) {
        return appStore.ui.selectedRequirementBlockIds.includes(requirementBlock.id);
      }
      return false;
    },
    headerComponent: CheckboxHeaderCell,
    headerClass: checkboxHeaderCellStyles.checkboxHeaderCellWrapper,
    headerComponentParams: {
      getIndeterminate: (): boolean => {
        const totalItemsCount = reqPage.validatedBlocks.length;
        return !!selectedReqBlocksCount && totalItemsCount > selectedReqBlocksCount;
      },
      getChecked: (): boolean => {
        const totalItemsCount = reqPage.validatedBlocks.length;
        return !!totalItemsCount && totalItemsCount === selectedReqBlocksCount;
      },
      onToggleSelection() {
        const { validatedBlockIds } = reqPage;
        appStore.ui.setSelectedReportBlockIds(selectedReqBlocksCount ? [] : validatedBlockIds);
      },
    } satisfies ICheckboxHeaderCellOwnProps,
    sortIndex: 0,
  };
};

const getActionsColumnDef = (reqPage: IRequirementsPage): ColDef<IRequirementBlock> => ({
  colId: ERequirementsTableColumn.ACTIONS,
  headerName: "",
  pinned: "left",
  lockPosition: "left" as const,
  resizable: false,
  width: 25,
  maxWidth: 25,
  suppressSizeToFit: true,
  suppressColumnsToolPanel: true,
  cellRenderer: RequirementsActionsCell,
  cellRendererParams: { onDeleteRequirementBlock: reqPage.deleteRequirementBlock } as IRequirementsActionsCellOwnProps,
});

export const getAddNewColumnDef = (reqPage: IRequirementsPage, workspace: IWorkspace): ColDef<IRequirementBlock> => ({
  width: 50,
  lockPosition: "right" as const,
  pinned: "right",
  colId: ERequirementsTableColumn.ADD_NEW,
  headerComponent: AddColumnHeaderRenderer,
  headerClass: styles.requirementsTableAddColumnHeader,
  suppressSizeToFit: true,
  suppressColumnsToolPanel: true,
  headerComponentParams: {
    reqPageId: reqPage.id,
    statusDefinitions: workspace.statusDefinitions.filter(statusDefinition => !reqPage.hasStatusColumn(statusDefinition.id)),
    onAddStatusColumn: reqPage.addStatusColumn,
    onAddNewStatusDefinition: workspace.addNewStatusDefinition,
  } satisfies IAddColumnHeaderRendererOwnProps,
});

const exportAnnotationFormatter = (params: ValueFormatterParams<IRequirementBlock>): string => {
  let result = "";

  params.data?.annotationList.annotations.forEach(annotation => {
    const thread = annotation.commentThread;

    if (thread && thread.parentComment) {
      result += `${thread.parentComment.user?.displayName || "Unknown user"} commented: ${removeTags(thread.parentComment.text)}`;

      for (const comment of thread.childComments) {
        result += `\n${comment.user?.displayName || "Unknown user"} replied: ${removeTags(comment.text)}`;
      }
    }
  });

  return result;
};

const exportVerificationValueFormatter = (params: ValueFormatterParams<IRequirementBlock>): string => {
  const requirementBlock = params.data;

  if (!requirementBlock || requirementBlock.type !== RequirementBlockType.requirement) {
    return "";
  }

  if (requirementBlock.successCriteria === SuccessCriteriaType.Automatic) {
    return `${requirementBlock.validationOperation || ""} ${requirementBlock.validationFormula || ""}`;
  } else {
    return renderManualVerificationLabel(requirementBlock.manualVerification);
  }
};

const getMetaColumnDef = (
  metaColumn: ERequirementsTableColumn,
  column: ITableColumn,
  reqPage: IRequirementsPage,
  simplified?: boolean
): ColDef<IRequirementBlock> | undefined => {
  switch (metaColumn) {
    case ERequirementsTableColumn.ID:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 100,
        hide: column.hide,
        minWidth: 100,
        lockPosition: "left" as const,
        colId: column.id,
        headerName: ERequirementsTableColumn.ID,
        ...(!simplified && {
          cellRenderer: TextCellRenderer,
          cellRendererParams: { className: styles.requirementsTableIdCellRenderer } satisfies ITextCellRendererOwnProps,
          cellEditor: RequirementEditorCell,
          cellClassRules: {
            "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
          },
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => {
          if (isAlive(cellProps.data)) {
            return cellProps.data?.computedVisibleId ?? "";
          }
        },
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setVisibleId(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.LEVEL:
      return {
        width: column.width ?? 70,
        hide: column.hide,
        minWidth: 70,
        lockPosition: "left" as const,
        colId: column.id,
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.level ?? "",
        headerName: ERequirementsTableColumn.LEVEL,
        ...(!simplified && {
          cellRenderer: levelCellRenderer,
          cellClass: "select-cell-wrapper",
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.TITLE:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 140,
        hide: column.hide,
        minWidth: 140,
        lockPosition: "left" as const,
        suppressMovable: true,
        colId: column.id,
        headerName: ERequirementsTableColumn.TITLE,
        ...(!simplified && {
          cellEditor: RequirementEditorCell,
          cellRenderer: titleCellRenderer,
          cellClassRules: {
            "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
          },
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => removeTags(params.data?.label ?? ""),
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.label ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setLabel(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.FUNCTIONAL_TYPE:
      return {
        width: column.width ?? 110,
        minWidth: 110,
        hide: column.hide,
        headerName: ERequirementsTableColumn.FUNCTIONAL_TYPE,
        colId: column.id,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) =>
          params.data?.type === RequirementBlockType.requirement ? params.data?.functionalType || "" : "",
        ...(!simplified && {
          cellRenderer: functionalTypeCellRenderer,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.COMMENTS:
      return {
        width: 44,
        headerName: ERequirementsTableColumn.COMMENTS,
        colId: column.id,
        resizable: false,
        ...(!simplified && {
          headerComponent: CommentHeader,
          cellRenderer: CommentCellRenderer,
          cellRendererParams: {
            lastFocusedCommentId: reqPage.lastFocusedCommentId,
            onFocusComment: reqPage.setLastFocusedCommentId,
            getEntityIdsWithComments: () => reqPage.getReqBlockIdsWithComments(true),
          } satisfies ICommentCellRendererParams,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
        valueFormatter: exportAnnotationFormatter,
        valueGetter: (params: ValueGetterParams<IRequirementBlock, ICommentCellRendererValue>) => {
          return {
            entityId: params.data?.id ?? "",
            annotationList: params.data?.annotationList,
          } satisfies ICommentCellRendererValue;
        },
      };
    case ERequirementsTableColumn.RATIONALE:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.RATIONALE,
        ...(!simplified && {
          cellRenderer: rationaleCellRenderer,
          cellClassRules: {
            "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
          },
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
          cellEditor: RequirementEditorCell,
        }),
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => removeTags(params.data?.rationale ?? ""),
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.rationale ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setRationale(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.REQUIREMENT_STATEMENT:
    case ERequirementsTableColumn.DESCRIPTION:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: "Requirement Statement",
        ...(!simplified && {
          cellRenderer: descriptionCellRenderer,
          cellClassRules: {
            "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
          },
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
          cellEditor: RequirementEditorCell,
        }),
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => removeTags(params.data?.description ?? ""),
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.description ?? "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setDescription(cellProps.newValue);
          return true;
        },
      };
    case ERequirementsTableColumn.LINKED_BLOCK:
      return {
        width: column.width ?? 100,
        hide: column.hide,
        minWidth: 100,
        colId: column.id,
        headerName: ERequirementsTableColumn.LINKED_BLOCK,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => {
          const property = params.data?.linkedProperty ? getPropertyInstanceById(params.data?.linkedProperty) : undefined;
          return property?.parentBlock?.label || "";
        },
        ...(!simplified && {
          cellRenderer: linkedBlockCellRenderer,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.LINKED_PROPERTY:
      return {
        width: column.width ?? 180,
        hide: column.hide,
        minWidth: 100,
        colId: column.id,
        headerName: ERequirementsTableColumn.LINKED_PROPERTY,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => {
          const property = params.data?.linkedProperty ? getPropertyInstanceById(params.data?.linkedProperty) : undefined;
          return property ? `${property.label}: ${property.numericText}` : "";
        },
        ...(!simplified && {
          cellRenderer: linkedPropertyCellRenderer,
          cellClass: "linked-property-cell--wrapper",
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.METHOD:
      return {
        width: column.width ?? 120,
        hide: column.hide,
        minWidth: 120,
        colId: column.id,
        headerName: ERequirementsTableColumn.METHOD,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) =>
          params.data?.type === RequirementBlockType.requirement ? (params.data?.verificationMethods.join(",") ?? "") : "",
        ...(!simplified && {
          cellRenderer: methodCellRenderer,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.VERIFICATION:
      return {
        width: column.width ?? 160,
        hide: column.hide,
        minWidth: 160,
        colId: column.id,
        headerName: ERequirementsTableColumn.VERIFICATION,
        valueFormatter: exportVerificationValueFormatter,
        ...(!simplified && {
          cellRenderer: verificationCellRenderer,
          cellClass: styles.requirementsTableNoPaddingCellWrapper,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.SUCCESS_CRITERIA:
      return {
        width: column.width ?? 130,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.SUCCESS_CRITERIA,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) =>
          params.data?.type === RequirementBlockType.requirement ? params.data?.successCriteria || "" : "",
        ...(!simplified && {
          cellRenderer: successCriteriaCellRenderer,
          cellClass: "select-cell-wrapper",
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.VERIFICATION_STATUS:
      return {
        width: column.width ?? 120,
        hide: column.hide,
        minWidth: 120,
        colId: column.id,
        headerName: ERequirementsTableColumn.VERIFICATION_STATUS,
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) =>
          params.data?.type === RequirementBlockType.requirement ? params.data?.verificationStatus || "" : "",
        ...(!simplified && {
          cellRenderer: verificationStatusCellRenderer,
          cellClass: styles.requirementsTableNoPaddingCellWrapper,
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
        }),
      };
    case ERequirementsTableColumn.NOTES:
      return {
        editable: (cellProps: EditableCallbackParams<IRequirementBlock>) => !cellProps.data?.locked,
        width: column.width ?? 180,
        hide: column.hide,
        minWidth: 130,
        colId: column.id,
        headerName: ERequirementsTableColumn.NOTES,
        ...(!simplified && {
          cellRenderer: notesCellRenderer,
          cellClassRules: {
            "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
          },
          headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
          cellEditor: RequirementEditorCell,
        }),
        valueFormatter: (params: ValueFormatterParams<IRequirementBlock>) => removeTags(params.data?.note ?? ""),
        valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => cellProps.data?.note || "",
        valueSetter: (cellProps: ValueSetterParams<IRequirementBlock>): boolean => {
          cellProps.data.setNote(cellProps.newValue);
          return true;
        },
      };
  }
};

const getStatusColumnDef = (params: {
  entity: IEntityData;
  column: ITableColumn;
  reqPage: IRequirementsPage;
  containerRef?: RefObject<HTMLDivElement>;
  simplified?: boolean;
}): ColDef<IRequirementBlock> => {
  const { entity, column, reqPage, simplified, containerRef } = params;
  const statusDefinition = appStore.workspaceModel?.statusDefinitionMap.get(entity.id);
  return {
    width: column.width ?? 140,
    hide: column.hide,
    minWidth: 100,
    colId: column.id,
    headerName: statusDefinition?.label ?? "",
    ...(!simplified && {
      cellRenderer: RequirementsStatusCell,
      cellRendererParams: { statusDefinition, containerRef } as IRequirementsStatusCellOwnProps,
      headerComponentParams: { tableColumn: column, reqPageId: reqPage.id } satisfies IRequirementsHeaderCellRendererOwnProps,
      cellClassRules: {
        "requirements-table--locked-cell": (cellProps: CellClassParams<IRequirementBlock>) => !!cellProps.data?.locked,
      },
    }),
    valueFormatter: (cellProps: ValueFormatterParams<IRequirementBlock>) => {
      let statusInstance: IStatusInstance | undefined;
      if (entity.type === EntityType.StatusInstance) {
        statusInstance = cellProps.data?.getStatusInstance(entity.id);
      } else if (entity.type === EntityType.StatusDefinition) {
        statusInstance = cellProps.data?.statusInstances.find(i => i.statusDefinition.id === entity.id);
      }
      return statusInstance ? statusCellValueFormatter(statusInstance) : "";
    },
    valueGetter: (cellProps: ValueGetterParams<IRequirementBlock>) => {
      if (entity.type === EntityType.StatusInstance) {
        return cellProps.data?.getStatusInstanceValue(entity.id) ?? "";
      }
    },
  };
};

export const mapReqTableColumnToColumnDef = (params: {
  column: ITableColumn;
  reqPage: IRequirementsPage;
  containerRef?: RefObject<HTMLDivElement>;
  simplified?: boolean;
}): ColDef<IRequirementBlock> | undefined => {
  const { column, reqPage, containerRef, simplified } = params;
  if (column.entity) {
    return getStatusColumnDef({ entity: column.entity, column, reqPage, simplified, containerRef });
  } else if (column.metaColumn) {
    return getMetaColumnDef(column.metaColumn as ERequirementsTableColumn, column, reqPage, simplified);
  }
};

export const useReqTableColumnDefs = (reqPage: IRequirementsPage, containerRef: RefObject<HTMLDivElement>): ColDef[] => {
  const columnsOrder = appStore.env.columnsOrder.requirementsTable;
  const workspace = useWorkspace();
  const { orderedColumns } = reqPage;
  const columnDefs = useMemo(
    () => {
      return [
        getCheckboxColumnDef(reqPage),
        getActionsColumnDef(reqPage),
        ...orderedColumns.map(column => mapReqTableColumnToColumnDef({ column, reqPage, containerRef })).filter(isDefined),
        getAddNewColumnDef(reqPage, workspace),
      ] satisfies ColDef<IRequirementBlock>[];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reqPage, orderedColumns, orderedColumns.length, columnsOrder, workspace]
  );

  return columnDefs;
};
