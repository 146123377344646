export const sanitizeInput = (value: string) => {
  let sanitizedValue = value
    // Remove leading digits
    .replace(/^\d+/, "");

  // Capitalize letters after spaces or special characters (skip first letter)
  sanitizedValue = sanitizedValue.replace(/(?:\s+|[^a-zA-Z_\d]+)([a-zA-Z])/g, (_match, letter) => {
    return letter.toUpperCase();
  });

  // Remove all special characters and spaces
  sanitizedValue = sanitizedValue.replace(/[^a-zA-Z_\d]/g, "");

  return sanitizedValue;
};
