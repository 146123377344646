import { ChangeEvent, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { H1, InputGroup } from "@blueprintjs/core";
import { useInfiniteScrollFetch } from "@hooks/useInfiniteScrollFetch";
import { useOnMount } from "@hooks/useOnMount";
import classNames from "classnames";
import { observer } from "mobx-react";
import { v4 as uuidV4 } from "uuid";

import { Button } from "@components/Button";
import { focusReportBlockById } from "@components/Reports/Editor/utils";
import ReportBlockLinkedListItem, {
  IReportBlockSortableListItemOwnProps,
} from "@components/Reports/ReportBlock/ReportBlockSortableListItem";
import SimpleSortableList from "@components/Shared/SortableLinkedLists/SimpleSortableList";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import { useDragToSelect } from "src/providers/DragToSelect/useDragToSelect";

import EmojiHolder from "../EmojiHolder";
import ReportCover from "../ReportCover";

import { ignoredLastItemClickTypes } from "./constants";

import "./ReportPage.scss";

const REPORT_CONTAINER_ID = "REPORT_CONTAINER_ID";
const REPORT_PAGE_CONTENT = "REPORT_PAGE_CONTENT";

type ReportPageProps = {
  report: IReport;
};

const defaultMargin = 50;
const buttonWidth = 30;
const visibleBlockBatchCount = 30;

const ReportPage = (props: ReportPageProps) => {
  const { report } = props;
  const [visibleBlocksCount, setVisibleBlocksCount] = useState(visibleBlockBatchCount);
  const viewers = appStore.workspaceViewers.filter(viewer => viewer.entity?.id === report.id);
  const { isPrintingPage } = appStore.ui;
  const { elementsContainerRef } = useDragToSelect({
    allIds: report.validatedBlockIds.slice(),
    selectedIds: appStore.ui.selectedReportBlockIds,
    onDelete: appStore.workspaceModel?.deleteReportBlocks,
    onSelect: appStore.ui.setSelectedReportBlockIds,
    onSelectAll: appStore.ui.setSelectedReportBlockIds,
    onResetSelection: appStore.ui.resetSelectedReportBlockIds,
  });

  const handleLoadMore = () => {
    setVisibleBlocksCount(visibleBlocksCount => visibleBlocksCount + visibleBlockBatchCount);
  };

  const { scrollContainerRef } = useInfiniteScrollFetch({
    onLoadMore: handleLoadMore,
    hasMore: visibleBlocksCount < report.validatedBlockIds.length,
    scrollThreshold: 1200,
  });

  useEffect(() => {
    scrollContainerRef.current?.scrollTo(0, 0);
    setVisibleBlocksCount(visibleBlockBatchCount);
  }, [report, scrollContainerRef]);

  useOnMount(() => {
    appStore.env.setActiveReport(report.id);
    return () => {
      appStore.env.clearActiveReportBlock();
    };
  });

  const handleUpdateTitle = (label: string) => {
    if (report.label !== label) {
      report.update({ label });
    }
  };

  const onTitleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const label = (e.target as EventTarget & HTMLInputElement).value;
    if (e.key === "Enter") {
      handleUpdateTitle(label);
      addPlaceholderBlock(0);
    }
  };

  const onTitleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const label = (e.target as EventTarget & HTMLInputElement).value;
    handleUpdateTitle(label);
  };

  const addPlaceholderBlock = (orderIndex?: number) => {
    const id = appStore.workspaceModel?.addReportBlock(report, RollupEditorType.p, "", orderIndex);
    setTimeout(() => {
      const newBlockElement = document.getElementById(id || "");
      (newBlockElement?.firstChild as HTMLDivElement).focus();
    }, 25);
  };

  const handlePushPlaceholder = () => {
    const latestReportBlock = report.reportBlocks.at(-1);

    if (latestReportBlock && !ignoredLastItemClickTypes.includes(latestReportBlock.type)) {
      const editorHtml = document.getElementById(latestReportBlock?.id);
      if (editorHtml?.innerText.trim().length) {
        addPlaceholderBlock();
      } else {
        (editorHtml?.firstChild as HTMLDivElement).focus();
      }
    } else {
      addPlaceholderBlock();
    }
  };

  const handleMoveBlocks = (ids: string[], targetId: string) => {
    if (ids.length === 1) {
      report.moveBlock(ids[0], targetId);
    } else {
      report.moveBlocks(ids, targetId);
    }
  };

  const handleMouseUp = (e: any) => {
    const isEmptySpaceClick = e.target.id === REPORT_PAGE_CONTENT;

    if (isEmptySpaceClick && !appStore.ui.selectedReportBlockIds.length) {
      handlePushPlaceholder();
    }
  };

  const handlePageMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    const isEmptySpaceClick = [REPORT_CONTAINER_ID, REPORT_PAGE_CONTENT].includes((e.target as HTMLElement).id);
    if (isEmptySpaceClick && appStore.ui.selectedReportBlockIds.length) {
      appStore.ui.setSelectedReportBlockIds([]);
    }
  };

  const reportPageClassName = classNames("report-page--content", {
    ["report-page--content-full"]: report.fullWidth,
    ["report-page--no-cover"]: !report.coverUrl,
  });

  const renderButtons = () => {
    if (report.coverUrl && report.icon) {
      return null;
    }

    return (
      <div className="report-page--buttons">
        {!report.coverUrl && <ReportCover report={report} />}
        {!report.icon && <EmojiHolder report={report} />}
      </div>
    );
  };

  const handleAddReportBlock = useCallback(
    (block: IReportBlock, type = RollupEditorType.p, label = "", above = false) => {
      const indexModifier = above ? 0 : 1;
      const orderIndex = report.validatedBlocks.findIndex(b => b.id === block.id) + indexModifier;
      const id = uuidV4();
      const newBlockId = appStore.workspaceModel?.addReportBlock(report, type, label, orderIndex, id);
      const focusStart = above || label;
      focusReportBlockById(above ? block.id : id, !focusStart);
      return newBlockId;
    },
    [report]
  );

  const marginLeft = viewers.length * buttonWidth + defaultMargin;

  return (
    <div
      className={classNames("report-page", "drag-to-select-scroll-container")}
      ref={scrollContainerRef}
      id={REPORT_CONTAINER_ID}
      onMouseDown={handlePageMouseDown}
      data-dragselectable
    >
      {report.coverUrl && <ReportCover report={report} />}
      <div id={REPORT_PAGE_CONTENT} onMouseUp={handleMouseUp} className={reportPageClassName}>
        {report.icon && <EmojiHolder report={report} />}
        {renderButtons()}
        <div data-dragselectable={false}>
          <H1>
            <InputGroup
              inputClassName="report-page--title-input"
              autoFocus={!report.label}
              className="report-page--title"
              key={`${report.id}${report.label}`}
              placeholder="Untitled"
              onBlur={onTitleBlur}
              defaultValue={report.label}
              onKeyUp={onTitleKeyPress}
            />
          </H1>
          {!report.reportBlocks.length && (
            <div className="report-page--empty">
              <div>
                <Button onClick={handlePushPlaceholder} icon="document" e2eIdentifiers="report-create-empty" minimal>
                  Empty page
                </Button>
              </div>
              <Button onClick={appStore.ui.showReportTemplatesDialog} icon="search-template" e2eIdentifiers="report-templates" minimal>
                Templates
              </Button>
            </div>
          )}
          <div style={{ marginLeft: `-${marginLeft}px` }}>
            <SimpleSortableList<IReportBlockSortableListItemOwnProps>
              items={isPrintingPage ? report.validatedBlockIds : report.validatedBlockIds.slice(0, visibleBlocksCount)}
              itemComponent={ReportBlockLinkedListItem}
              itemComponentCustomProps={{ hasSingleBlock: report.validatedBlockIds.length === 1, onAddReportBlock: handleAddReportBlock }}
              wrapperRef={elementsContainerRef}
              defaultSelectedIds={appStore.ui.selectedReportBlockIds}
              onMultipleItemsDragEnd={handleMoveBlocks}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ReportPage);
