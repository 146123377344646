import { observer } from "mobx-react";

import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import appStore from "@store/AppStore";
import { getPluralOrSingularString } from "@utilities";

const DeleteRequirementBlockDialog = () => {
  const { selectedRequirementBlockIds } = appStore.ui;
  const amount = selectedRequirementBlockIds.length;
  const showModal = appStore.ui.showDeleteRequirementBlocksModal;

  if (!showModal) {
    return null;
  }

  const handleConfirm = () => {
    appStore.workspaceModel?.deleteRequirementBlocks(selectedRequirementBlockIds);
    appStore.ui.hideDeleteRequirementBlocksModal();
    appStore.ui.setSelectedRequirementBlockIds([]);
  };

  return (
    <DeleteConfirmationDialog
      titleItem={`requirement ${getPluralOrSingularString(amount, "block")}`}
      description={`Are you sure you want to delete ${amount} requirement ${getPluralOrSingularString(amount, "block")}?`}
      onCancel={appStore.ui.hideDeleteRequirementBlocksModal}
      onClose={appStore.ui.hideDeleteRequirementBlocksModal}
      isOpen
      onConfirm={handleConfirm}
    />
  );
};

export default observer(DeleteRequirementBlockDialog);
