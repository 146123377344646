import { memo, useCallback, useRef, useState } from "react";
import { Box, boxesIntersect, SelectionBox, useSelectionContainer } from "@air/react-drag-to-select";
import { useThrottledCallback } from "@hooks/useThrottledCallback";
import isEqual from "lodash/isEqual";
import { observer } from "mobx-react";

import { isDefined } from "@utilities/TypeGuards";

import DragToSelectContext from "./DragToSelectContext";
import { TDragToSelectProviderProps, TSelectableItemBox } from "./types";

const mapToItemBox = (item: HTMLElement, scrollContainer: HTMLElement | null): TSelectableItemBox | undefined => {
  const id = item.dataset.id;
  if (!id) {
    return;
  }

  const { left, top, width, height } = item.getBoundingClientRect();

  return {
    id,
    left: left + (scrollContainer?.scrollLeft ?? 0),
    top: top + (scrollContainer?.scrollTop ?? 0),
    width,
    height,
  };
};

const DragToSelectProvider = (props: TDragToSelectProviderProps) => {
  const elementsContainerRef = useRef<HTMLDivElement>(null);
  const selectableItemBoxes = useRef<TSelectableItemBox[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onSelectionChange = useCallback(
    (box: SelectionBox) => {
      if (!selectableItemBoxes.current.length) {
        return;
      }
      const scrollContainer = document.querySelector('[class*="drag-to-select-scroll-container"]');
      const scrollY = scrollContainer?.scrollTop ?? window.scrollY;
      const scrollX = scrollContainer?.scrollLeft ?? window.scrollX;
      const scrollAwareBox: Box = {
        ...box,
        top: box.top + scrollY,
        left: box.left + scrollX,
      };

      const firstElement = selectableItemBoxes.current[0];
      const isBoxToTheLeft = scrollAwareBox.left + scrollAwareBox.width < firstElement.left;
      const isBoxToTheRight = scrollAwareBox.left > firstElement.left + firstElement.width;

      if (isBoxToTheLeft || isBoxToTheRight) {
        if (selectedIds.length) {
          setSelectedIds([]);
        }
        return;
      }

      const idsToSelect: string[] = [];

      // TODO consider improving this with a binary search
      for (const item of selectableItemBoxes.current) {
        if (boxesIntersect(scrollAwareBox, item) && !idsToSelect.includes(item.id)) {
          idsToSelect.push(item.id);
        } else if (scrollAwareBox.top + scrollAwareBox.height < item.top) {
          break;
        }
      }

      const activeElement = document.activeElement as HTMLElement | null;
      idsToSelect.length > 1 && activeElement && activeElement.blur();

      if (!isEqual(selectedIds, idsToSelect)) {
        setSelectedIds(idsToSelect);
      }
    },
    [selectedIds]
  );

  const onSelectionChangeThrottled = useThrottledCallback(onSelectionChange, 100);

  const { DragSelection } = useSelectionContainer({
    selectionProps: {
      style: {
        zIndex: 2,
      },
    },
    onSelectionStart: () => {
      // Prevents text selection
      window.getSelection()?.removeAllRanges();
      const selectableElements = Array.from(elementsContainerRef.current?.children ?? []) as HTMLElement[];
      const scrollContainer = document.querySelector<HTMLElement>('[class*="drag-to-select-scroll-container"]');
      selectableItemBoxes.current = selectableElements.map((item: HTMLElement) => mapToItemBox(item, scrollContainer)).filter(isDefined);
      setSelectedIds([]);
    },
    eventsElement: document.getElementById("root"),
    shouldStartSelecting: (target: EventTarget | null) => {
      if (target instanceof HTMLElement) {
        let el = target;
        while (el.parentElement && !el.dataset.dragselectable) {
          el = el.parentElement;
        }
        return el.dataset.dragselectable === "true";
      }

      return false;
    },
    onSelectionChange: onSelectionChangeThrottled,
  });

  return (
    <DragToSelectContext.Provider value={{ selectedIds, elementsContainerRef }}>
      <DragSelection />
      {props.children}
    </DragToSelectContext.Provider>
  );
};

export default memo(observer(DragToSelectProvider), (props: any, nextProps: any) => {
  return props.children.length === nextProps.children.length;
});
