import { useCallback, useEffect, useRef, useState } from "react";
import { ButtonGroup, Intent, NonIdealState, Position, ResizeSensor, Spinner } from "@blueprintjs/core";
import { Editor } from "@monaco-editor/react";
import { observer } from "mobx-react";
import type * as Monaco from "monaco-editor";
import { KeyCode, KeyMod } from "monaco-editor";
import { useDebounceValue } from "usehooks-ts";

import { EnvironmentTypeSelect } from "@components/Analysis";
import { Button } from "@components/Button";
import { ModulePageHeader } from "@components/Shared/ModulePageHeader";
import { Tooltip } from "@components/Tooltip";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { ExecutionEnvironment, ExecutionEnvironmentType, isExecutionStatusFinal } from "@rollup-api/models/execution-environments";
import { runAnalysis } from "@rollup-api/utils";
import { AnalysisType, IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { getLanguageFromType } from "@utilities";
import { rollupClient } from "src/core/api";
import AnalysisInputOutputSidebar from "src/Modules/Analysis/AnalysisInputOutputSidebar/AnalysisInputOutputSidebar";
import ExecutionEnvironmentSelect from "src/Modules/Analysis/CodeBlocks/ExecutionEnvironmentSelect";

import AnalysisFooter from "../AnalysisFooter/AnalysisFooter";
import AnalysisSidebarContextMenu from "../AnalysisSidebarContextMenu/AnalysisSidebarContextMenu";
import Spreadsheet from "../Spreadsheet/Spreadsheet";

import styles from "./Analysis.module.scss";

const SHOW_SIDE_BAR_WIDTH = 1000;
const IO_SIDEBAR_WIDTH = 400;
const AUTOSAVE_DEBOUNCE_DURATION = 2000;

function AnalysisPage({ analysis }: { analysis: IAnalysis }) {
  const [isFetchingCode, setIsFetchingCode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [analysisContents, setAnalysisContents] = useState<string>("");
  const [isLoadingEditor, setIsLoadingEditor] = useState(true);
  const [editorWidth, setEditorWidth] = useState<number>(-1);

  const decorationRef = useRef<Monaco.editor.IEditorDecorationsCollection>();

  const [debouncedAnalysisContents] = useDebounceValue(analysisContents, AUTOSAVE_DEBOUNCE_DURATION);

  // TODO: I think we could should be able to use the useQuery hook here and in the execution dialog
  const [isFetchingEnvs, setIsFetchingEnvs] = useState(false);
  const [availableEnvironments, setAvailableEnvironments] = useState<ExecutionEnvironment[]>();

  // TODO: Extend this to also accommodate spreadsheets or (if not feasible) create a separate updater
  const updateCodeIfRequired = useCallback(
    async (code: string) => {
      const originalCode = analysis.code;
      if (originalCode !== null && code !== originalCode) {
        await analysis.setCode(code, true);
      }
    },
    [analysis]
  );

  // Fetch execution environments if code block
  useEffect(() => {
    if (!analysis) {
      return;
    }
    if (analysis.analysisType === AnalysisType.CodeBlock) {
      setIsFetchingEnvs(true);
      rollupClient.analysisModule.executionEnvironments
        .retrieveList()
        .then(res => {
          setAvailableEnvironments(res.data);
        })
        .catch(error => {
          console.warn("Error fetching execution environments", error);
          showApiErrorToast("Error fetching execution environments", error);
        })
        .finally(() => {
          setIsFetchingEnvs(false);
        });
    }
  }, [analysis]);

  // Fetch analysis code/data or spreadsheet contents
  useEffect(() => {
    if (!analysis) {
      return;
    }
    setIsFetchingCode(true);
    analysis.clearCode();
    rollupClient.analysisModule.analyses
      .retrieve(analysis.id)
      .then(fullBlock => {
        if (fullBlock.data) {
          setAnalysisContents(fullBlock.data.code || "");
          analysis.setCode(fullBlock.data.code || "", false);
        }
      })
      .finally(() => {
        setIsFetchingCode(false);
      });
  }, [analysis]);

  // Realtime code updates: subscribe to room and handle code update events
  useEffect(() => {
    if (!appStore.workspaceModel) {
      return;
    }

    // TODO: rename to analysis room
    const codeBlockRoom = `workspace/${appStore.workspaceModel.id}/code-block/${analysis.id}`;
    appStore.realtimeService.subscribeToChanges(codeBlockRoom);

    // Add handler for updating code
    const handleCodeUpdate = ({ id, code }: { id: string; code: string }) => {
      if (analysis.id === id) {
        analysis.setCode(code, false);
        setAnalysisContents(code);
      }
    };
    const removeHandlerCallback = appStore.realtimeService.addEventHandler("updateCodeBlockCode", handleCodeUpdate);

    return () => {
      removeHandlerCallback();
      appStore.realtimeService.unsubscribeFromChanges(codeBlockRoom);
    };
  }, [analysis]);

  useEffect(() => {
    updateCodeIfRequired(debouncedAnalysisContents);
    // eslint-disable-next-line
  }, [debouncedAnalysisContents]);

  const executeCode = async (code: string) => {
    if (isFetchingCode || !code) {
      return;
    }
    setIsSubmitting(true);
    await updateCodeIfRequired(code);
    await runAnalysis(analysis.id);
    setIsSubmitting(false);
  };

  const onResize = (entries: ResizeObserverEntry[]) => {
    const width = entries?.[0]?.contentRect.width;
    setEditorWidth(width);
  };

  // Only for code editor, spreadJS init is handled separately
  const onEditorMounted = (editor: Monaco.editor.IStandaloneCodeEditor) => {
    setIsLoadingEditor(false);
    editor.addCommand(KeyMod.CtrlCmd | KeyCode.Enter, () => {
      const value = editor.getValue();
      setAnalysisContents(value);
      executeCode(value);
    });
    editor.focus();
    decorationRef.current = editor.createDecorationsCollection([]);
    editor.onDidBlurEditorText(() => {
      updateCodeIfRequired(editor.getValue());
    });
  };

  const handleTemplateClicked = async () => {
    if (!(await appStore.workspaceModel?.analysis?.populateAnalysisTemplate(analysis))) {
      showApiErrorToast("Error creating code template", new Error());
      return;
    }
    if (analysis.code) {
      setAnalysisContents(analysis.code);
    }
  };

  const codeBlockType = analysis.analysisType === AnalysisType.CodeBlock;

  const onExecutionTypeChange = (type: ExecutionEnvironmentType) => {
    analysis.setType(type);
    analysis.setExecutionEnvironmentId(null);
  };

  const isChanged = analysisContents !== analysis.code;
  const isExecuting = analysis.latestExecution?.status && !isExecutionStatusFinal(analysis.latestExecution.status);
  const selectedEnv = availableEnvironments?.find(env => env.id === analysis.executionEnvironmentId);
  const canExecute =
    !isFetchingCode && !isSubmitting && !isExecuting && analysisContents && (selectedEnv || !analysis.executionEnvironmentId);

  if (!analysis) {
    return null;
  }

  const loadingPlaceholder = <NonIdealState icon="code-block" title="Loading analysis..." />;
  if (isFetchingCode) {
    return loadingPlaceholder;
  }

  const quickstartPlaceholder = (
    <div className={styles.placeholder}>
      Start typing or <a onClick={handleTemplateClicked}>press here</a> to start from a template
    </div>
  );

  const actions = (
    <div className={styles.actions}>
      {codeBlockType && (
        <>
          <EnvironmentTypeSelect value={analysis.type} onChange={onExecutionTypeChange} />
          {isFetchingEnvs ? (
            <Spinner />
          ) : (
            <ExecutionEnvironmentSelect
              selectedId={analysis.executionEnvironmentId}
              availableEnvironments={availableEnvironments}
              environmentType={analysis.type}
              onChange={id => analysis.setExecutionEnvironmentId(id)}
            />
          )}
        </>
      )}
      <ButtonGroup>
        <Tooltip content={`Save changes and ${codeBlockType ? "execute" : "calculate"}`} position={Position.BOTTOM}>
          <Button
            icon="play"
            disabled={!canExecute}
            loading={isExecuting}
            intent={Intent.SUCCESS}
            onClick={() => executeCode(analysisContents)}
            e2eIdentifiers="run-code"
          />
        </Tooltip>
        <Tooltip content={`Stop ${codeBlockType ? "execution" : "calculation"}`}>
          <Button icon="stop" intent={Intent.WARNING} disabled e2eIdentifiers="stop-code" />
        </Tooltip>
        <Tooltip content="Save changes">
          <Button icon="floppy-disk" disabled={!isChanged} e2eIdentifiers="save-code" />
        </Tooltip>
      </ButtonGroup>
    </div>
  );

  return (
    <div className={styles.analysisPage}>
      <ModulePageHeader<IAnalysis>
        entityName="code block"
        entity={analysis}
        unsaved={isChanged}
        showLink
        contextMenu={<AnalysisSidebarContextMenu analysis={analysis} />}
        rightActions={actions}
      />
      <ResizeSensor onResize={onResize}>
        <div className={styles.mainContent}>
          {!isLoadingEditor && !analysisContents ? quickstartPlaceholder : null}
          {codeBlockType ? (
            <Editor
              className={styles.editor}
              options={{
                minimap: { enabled: editorWidth - IO_SIDEBAR_WIDTH >= SHOW_SIDE_BAR_WIDTH },
                fontFamily: "JetBrains Mono",
                fontLigatures: true,
                scrollBeyondLastLine: false,
                wordWrap: "on",
                glyphMargin: true,
                lineNumbers: "on",
                fixedOverflowWidgets: true,
              }}
              width={editorWidth - IO_SIDEBAR_WIDTH}
              loading={loadingPlaceholder}
              onMount={onEditorMounted}
              language={getLanguageFromType(analysis.type)}
              value={analysisContents}
              onChange={value => setAnalysisContents(value ?? "")}
              theme={appStore.env.themeIsDark ? "vs-dark" : "light"}
            />
          ) : (
            <div className={styles.spreadsheetWrapper}>
              <Spreadsheet analysis={analysis} />
            </div>
          )}
          <AnalysisInputOutputSidebar analysis={analysis} />
        </div>
      </ResizeSensor>
      <AnalysisFooter analysis={analysis} result={analysis.latestExecution} />
    </div>
  );
}

export default observer(AnalysisPage);
